import React from 'react';
import CarsCategoryLinks from './components/CarsCategoryLinks';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import { TCategoryItem } from '@hz-design-system/web-ui/src/types/TCategoryItem';
import { TEnvConfig } from 'types/TServerContext';

type TCarsCategoryLinksAppProps = {
  carsCategoryLinks: TCategoryItem[];
  environment: TEnvConfig;
};

const CategoryLinksApp = ({ carsCategoryLinks, environment }: TCarsCategoryLinksAppProps) => (
  <EnvironmentProvider value={environment}>
    <CarsCategoryLinks carsCategoryLinks={carsCategoryLinks} />
  </EnvironmentProvider>
);

export default CategoryLinksApp;
