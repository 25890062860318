import { VEHICLE_TYPES } from './vehicleTypes';

const SIMPLE_SEARCH_FIELDS = {
  [VEHICLE_TYPES.CARS]: ['l2CategoryId', 'model', 'priceType', 'PriceCents', 'fuel', 'constructionYear'],
  [VEHICLE_TYPES.VANS]: ['brand', 'transmission', 'priceType', 'PriceCents', 'fuel', 'constructionYear'],
  [VEHICLE_TYPES.OLDTIMERS]: ['brand', 'PriceCents', 'fuel', 'constructionYear'],
  [VEHICLE_TYPES.TRUCKS]: ['brand', 'PriceCents', 'fuel', 'constructionYear'],
  EV: ['l2CategoryId', 'model', 'priceType', 'PriceCents', 'fuel', 'range', 'batteryChargeTime', 'batteryCapacity'],
};

export default SIMPLE_SEARCH_FIELDS;
