import React, { useState, useEffect, useContext } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryButton, Icon } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import { trackGAEvent } from '../../../utils/gaTracking';

import { TFacet } from 'types/TCarsSearchFields';
import EnvironmentContext from '../../../contexts/EnvironmentContext';

type TCarsSearchFormCheckboxGroupProps = {
  fieldKey: string;
  formField: TFacet;
  values?: string[];
  onChangeHandler: Function;
  isTenantSpecific?: boolean;
  withoutLabel?: boolean;
};

let BasicModal;
let Tooltip;
const LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN = 'InfoTooltipDefaultPriceTypeSeen';
const LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE = 'DefaultPriceType';

const CarsSearchFormToggleGroup = ({
  formField,
  fieldKey,
  values,
  onChangeHandler,
  withoutLabel,
}: TCarsSearchFormCheckboxGroupProps) => {
  const { t } = useI18nContext();
  const { tenantContext, experimentUser, priceTypeExperimentVariant, houstonClientConfig } =
    useContext(EnvironmentContext);
  const tenantKey = tenantContext.tenant;
  const [leaseModalOpen, setLeaseModalOpen] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [houston, setHouston] = useState<any>();

  useEffect(() => {
    if (priceTypeExperimentVariant !== 'B') {
      return;
    }

    try {
      const storageItemTooltip = localStorage.getItem(LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN);
      if (!storageItemTooltip) {
        (async () => {
          Tooltip = (await import(/* webpackChunkName: "Tooltip" */ './Tooltip')).default;
          setShowInfoTooltip(true);
        })();
      }

      const storageItemDefaultPriceType = localStorage.getItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE);
      if (storageItemDefaultPriceType) {
        const userDefaultPriceType = JSON.parse(storageItemDefaultPriceType);
        onChangeHandler(fieldKey, userDefaultPriceType.toString(), formField?.type, true);
      }
    } catch (e) {}
  }, [priceTypeExperimentVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!values || priceTypeExperimentVariant !== 'B') {
      return;
    }

    const priceTypeAttributeValue = values[0];
    if (priceTypeAttributeValue.length) {
      localStorage.setItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE, JSON.stringify(Number(priceTypeAttributeValue)));
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY_DEFAULT_PRICE_TYPE);
    }
  }, [values, priceTypeExperimentVariant]);

  const leaseInfoOnClick = async () => {
    trackGAEvent('priceTypeTooltipClicked');
    try {
      BasicModal = (await import(/* webpackChunkName: "BasicModal" */ '@hz-design-system/web-ui')).BasicModal;
      setLeaseModalOpen(!leaseModalOpen);
    } catch (e) {}
  };

  if (!formField?.options?.length) {
    return null;
  }

  const handleOnClick = async (event, option) => {
    onChangeHandler(fieldKey, option.value.toString(), formField?.type, true);
    setShowInfoTooltip(false);
    localStorage.setItem(LOCAL_STORAGE_KEY_PRICE_TYPE_SEEN, JSON.stringify(true));

    if (priceTypeExperimentVariant) {
      let houstonInstance = houston;
      if (!houston) {
        try {
          const houstonModule = await import(/* webpackChunkName: "houston" */ '@ecg-marktplaats/houston-integration');
          await houstonModule.createOptimizelyClient({ houstonConfig: houstonClientConfig });
          setHouston(houstonModule);
          houstonInstance = houstonModule;
        } catch (err) {}
      }

      houstonInstance.trackExperiment({
        eventKey: 'price_type_change',
        experimentUser,
        tenantKey,
      });
    }

    event.target.blur();
  };

  return (
    <div className="FormField FormField--toggleGroup">
      {!withoutLabel && (
        <label className="FormField-Label hz-Text hz-Text--bodyLarge u-colorTextSecondary" htmlFor={fieldKey}>
          {formField.label}
          <Icon className="ModalTrigger" name="info" color="signal-action-default" onClick={leaseInfoOnClick} />
        </label>
      )}
      <div className="ToggleGroup-formControl">
        <SecondaryButton
          type="button"
          className={
            !values || values[0] === '' || !values.length || values.length === formField.attributeGroup?.length
              ? 'isActive'
              : ''
          }
          onClick={(e) => {
            handleOnClick(e, { value: '' });
          }}
        >
          {t('cars.search.options.all')}
        </SecondaryButton>
        {formField.options.map((option) => (
          <SecondaryButton
            type="button"
            className={values && values.length === 1 && values.indexOf(option.value.toString()) >= 0 ? 'isActive' : ''}
            onClick={(e) => {
              handleOnClick(e, option);
            }}
            key={option.value}
          >
            {t(`cars.search.options.${option.value}`, option.label)}
          </SecondaryButton>
        ))}
        {withoutLabel && (
          <Icon className="ModalTrigger" name="info" color="signal-action-default" onClick={leaseInfoOnClick} />
        )}
      </div>
      {showInfoTooltip && <Tooltip />}
      {leaseModalOpen && (
        <BasicModal
          size="m"
          title={t('cars.search.pricetype.modal_title')}
          className="PriceTypeModal"
          onClose={() => setLeaseModalOpen(false)}
        >
          <Trans
            i18nKey="cars.search.pricetype.modal"
            paragraph-open={Trans.safeString('<p>')}
            paragraph-close={Trans.safeString('</p>')}
            title-open={Trans.safeString('<h5>')}
            title-close={Trans.safeString('</h5>')}
            bold-open={Trans.safeString('<div class="hz-Text hz-Text--bodyLargeStrong">')}
            bold-close={Trans.safeString('</div>')}
            list-open={Trans.safeString('<ul>')}
            list-close={Trans.safeString('</ul>')}
            list-item-open={Trans.safeString('<li>')}
            list-item-close={Trans.safeString('</li>')}
            break={Trans.safeString('<br>')}
          />
        </BasicModal>
      )}
    </div>
  );
};

export default CarsSearchFormToggleGroup;
