import React from 'react';
import Carousels from './components/Carousels';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

import { TCarousels } from 'types/TCarousels';
import { TEnvConfig } from 'types/TServerContext';

type TCarouselsAppProps = {
  carousels: TCarousels;
  favoriteAdIds: string[];
  environment: TEnvConfig;
};

const CarouselsApp = ({ carousels, favoriteAdIds, environment }: TCarouselsAppProps) => {
  return (
    <EnvironmentProvider value={environment}>
      <Carousels carousels={carousels} favoriteAdIds={favoriteAdIds} />
    </EnvironmentProvider>
  );
};

export default CarouselsApp;
