import React from 'react';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Icon, ButtonIcon, PrimaryButton } from '@hz-design-system/web-ui';

import { trackGAEvent } from '../../../utils/gaTracking';
import Classes from './CarsContinueSearch.scss';

type TCarsContinueSearchProps = {
  searchEntry: TSearchEntry;
  asCard?: boolean;
};

const CarsContinueSearch = ({
  searchEntry: {
    link,
    l2CategoryName,
    priceRangeFilter,
    constructionYearRangeFilter,
    mileageRangeFilter,
    attributeLabels = {},
  },
  asCard,
}: TCarsContinueSearchProps) => {
  const { t } = useI18nContext();
  const priceRange = priceRangeFilter ? `${t('cars.search.fields.rangeTo')} ${priceRangeFilter}` : '';
  const mileage = mileageRangeFilter ? `${t('cars.search.fields.rangeTo')} ${mileageRangeFilter}` : '';
  const constructionYear = constructionYearRangeFilter
    ? `${t('cars.search.fields.rangeFromUpward')} ${constructionYearRangeFilter}`
    : '';
  let modelFilter;
  const otherFilters = [];
  Object.values(attributeLabels).forEach((attrLabel) => {
    if (attrLabel?.facetId === 'model') {
      modelFilter = attrLabel.label;
    } else {
      // @ts-ignore
      otherFilters.push(attrLabel?.label || attrLabel); // backwards compatible
    }
  });
  const searchParts = [l2CategoryName, modelFilter, priceRange, mileage, constructionYear].concat(otherFilters);
  const searchString = searchParts.filter(Boolean).join(' · ');

  if (!searchString) {
    return;
  }

  const clickHandler = (event, lrpLink) => {
    event.preventDefault();
    trackGAEvent('Clicked', 'continue_search');
    window.location.assign(lrpLink);
  };

  return (
    <div className={`${Classes.root} ${asCard ? Classes.asCard : Classes.inline}`}>
      <Trans
        className={`${Classes.title} ${asCard ? 'hz-Title hz-Title--title5' : 'hz-Text hz-Text--bodyRegularStrong'}`}
        i18nKey={'continue_search.title'}
      />
      <div
        className={`${Classes.container} ${asCard ? 'hz-Card' : ''}`}
        onClick={(e) => {
          clickHandler(e, link);
        }}
      >
        <div title={searchString} className={Classes.searchParams}>
          {searchString}
        </div>
        {!asCard && <Icon className={Classes.icon} name="arrow-right" size="l" />}
        {asCard && (
          <PrimaryButton className={Classes.button}>
            <ButtonIcon isStartIcon name="search" />
            <span>{t('continue_search.button')}</span>
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default CarsContinueSearch;
