import fetchAPI from '../../../utils/fetchApi';

export default async function deleteFavorite(itemId, xsrfToken) {
  try {
    return await fetchAPI({
      url: `/cp/api/favorite`,
      method: 'DELETE',
      payload: { itemId },
      xsrfToken,
    });
  } catch (err) {
    return false;
  }
}
