import React, { useContext, useEffect, useState } from 'react';

import SearchCarousel from './SearchCarousel';
import createFavorite from '../repositories/createFavorite';
import deleteFavorite from '../repositories/deleteFavorite';
import { trackGAEvent } from '../../../utils/gaTracking';
import EnvironmentContext from '../../../contexts/EnvironmentContext';

import './Carousels.scss';
import Classes from './Carousels.scss';

import { TCarouselListingItem, TCarousels, TListingItemCarousel } from 'types/TCarousels';

type TCarouselsProps = {
  carousels: TCarousels;
  favoriteAdIds: string[];
};

const RECENTLY_VIEWED_VIPS_STORAGE_KEY = 'RecentlyViewedVips';
const SHUFFLE_PARAM = 0.5;
let ListingItemCarousel;
let buildCarsCarouselItemModel;
let checkListingAvailability;

const Carousels = ({ carousels, favoriteAdIds }: TCarouselsProps) => {
  const {
    xsrfToken,
    experiments: { isSmallFixesEnabled },
  } = useContext(EnvironmentContext);
  const [recentlyViewedCarousel, setRecentlyViewedCarousel] = useState<TListingItemCarousel>();
  const [favoriteAdItems, setFavoriteAdItems] = useState<TCarouselListingItem[]>(
    carousels.favoriteAds ? carousels.favoriteAds.items : [],
  );
  const [favoriteAdIdList, setFavoriteAdIdList] = useState(favoriteAdIds);
  const [refetchedListings, setRefetchedListings] = useState<string[]>([]);
  const [listingItemCarouselLoaded, setListingItemCarouselLoaded] = useState<boolean>(false);

  useEffect(() => {
    setFavoriteAdIdList(favoriteAdItems.map((adItem) => adItem.itemId));
  }, [favoriteAdItems]);

  const onErrorHandler = async (erroredItem: TCarouselListingItem) => {
    if (!isSmallFixesEnabled) {
      return;
    }
    if (refetchedListings.indexOf(erroredItem.itemId) >= 0) {
      return;
    }
    refetchedListings.push(erroredItem.itemId);
    setRefetchedListings(refetchedListings);

    try {
      const itemsStringValue = localStorage.getItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY);
      const items = itemsStringValue ? JSON.parse(itemsStringValue) : [];

      if (items.length) {
        checkListingAvailability = (
          await import(/* webpackChunkName: "checkListingAvailability" */ '../utils/checkListingAvailability')
        ).default;
        const availableListings = await checkListingAvailability([erroredItem], items);
        localStorage.setItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY, JSON.stringify(availableListings));

        buildCarsCarouselItemModel = (
          await import(/* webpackChunkName: "buildCarsCarouselItemModel" */ '../models/carouselItemModel')
        ).default;

        setRecentlyViewedCarousel({
          type: 'recently_viewed',
          items: buildCarsCarouselItemModel(availableListings),
        });
      }
    } catch (e) {}
  };

  const onFavClickHandler = async (item: TCarouselListingItem, isFavorite: Boolean) => {
    if (isFavorite) {
      trackGAEvent('WatchlistRemoveBegin');
      setFavoriteAdItems(favoriteAdItems.filter((ad) => ad.itemId !== item.itemId));
      await deleteFavorite(item.itemId, xsrfToken);
      trackGAEvent('WatchlistRemoveSuccess');
    } else {
      trackGAEvent('WatchlistAddBegin');
      setFavoriteAdItems([item, ...favoriteAdItems]);
      await createFavorite(item.itemId, xsrfToken);
      trackGAEvent('WatchlistAddSuccess');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const itemsStringValue = localStorage.getItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY);
        const items = itemsStringValue ? JSON.parse(itemsStringValue) : [];

        if (items.length) {
          let carsItems;
          try {
            buildCarsCarouselItemModel = (
              await import(/* webpackChunkName: "buildCarsCarouselItemModel" */ '../models/carouselItemModel')
            ).default;
            carsItems = buildCarsCarouselItemModel(items);
          } catch (e) {}

          if (carsItems.length) {
            try {
              ListingItemCarousel = (
                await import(/* webpackChunkName: "ListingItemCarousel" */ './ListingItemCarousel')
              ).default;
              setListingItemCarouselLoaded(true);
              setRecentlyViewedCarousel({
                type: 'recently_viewed',
                items: carsItems,
              });
            } catch (e) {}

            if (isSmallFixesEnabled) {
              const shuffledCarsItems = [...carsItems].sort(() => SHUFFLE_PARAM - Math.random());
              checkListingAvailability = (
                await import(/* webpackChunkName: "checkListingAvailability" */ '../utils/checkListingAvailability')
              ).default;
              const availableListings = await checkListingAvailability(shuffledCarsItems, items);
              localStorage.setItem(RECENTLY_VIEWED_VIPS_STORAGE_KEY, JSON.stringify(availableListings));
              buildCarsCarouselItemModel = (
                await import(/* webpackChunkName: "buildCarsCarouselItemModel" */ '../models/carouselItemModel')
              ).default;
              setRecentlyViewedCarousel({
                type: 'recently_viewed',
                items: buildCarsCarouselItemModel(availableListings),
              });
            }
          }
        }
      } catch (e) {}
    })();
  }, [isSmallFixesEnabled]);

  useEffect(() => {
    if (!favoriteAdItems.length) {
      return;
    }

    (async () => {
      try {
        ListingItemCarousel = (await import(/* webpackChunkName: "ListingItemCarousel" */ './ListingItemCarousel'))
          .default;
        setListingItemCarouselLoaded(true);
      } catch (e) {}
    })();
  }, [favoriteAdItems]);

  return (
    <div className={Classes.root}>
      {carousels.favoriteAds && listingItemCarouselLoaded && (
        <ListingItemCarousel
          type={carousels.favoriteAds.type}
          items={favoriteAdItems}
          favoriteAdIdList={favoriteAdIdList}
          onFavClickHandler={onFavClickHandler}
        />
      )}
      {recentlyViewedCarousel && listingItemCarouselLoaded && (
        <ListingItemCarousel
          type={recentlyViewedCarousel.type}
          items={recentlyViewedCarousel.items}
          favoriteAdIdList={favoriteAdIdList}
          onFavClickHandler={onFavClickHandler}
          onErrorHandler={onErrorHandler}
        />
      )}
      {carousels.needs && <SearchCarousel carousel={carousels.needs} />}
    </div>
  );
};

export default Carousels;
