import React from 'react';
import PageBackground from './components/PageBackground';

type TPageBackgroundAppProps = {
  page: 'cars' | 'ev';
};

const PageBackgroundApp = ({ page }: TPageBackgroundAppProps) => <PageBackground page={page} />;

export default PageBackgroundApp;
