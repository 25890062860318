export const ATTRIBUTE_VALUES = {
  PRICE_TYPE: {
    BUY: '10882',
    LEASE: '10883',
  },
  FUEL: {
    ELECTRIC: '11756',
  },
};

export const ATTRIBUTE_KEYS = {
  LEASE_PRICE_TYPE: 'Leaseprijs',
};

export const RANGE_ATTRIBUTE_KEYS = {
  NUMBER_OF_SEATS: 'numberOfSeats',
  NUMBER_OF_SEATS_BE: 'numberOfSeatsBE',
  ENGINE_HORSE_POWER: 'engineHorsepower',
  ENGINE_POWER_KW: 'enginePowerKW',
  ENGINE_DISPLACEMENT: 'engineDisplacement',
  NUMBER_OF_CYLINDERS: 'numberOfCilindersCars',
  BATTERY_CAPACITY: 'batteryCapacity',
  BATTERY_CHARGE_TIME: 'batteryChargeTime',
  BATTERY_FAST_CHARGE_TIME: 'batteryFastChargeTime',
  RANGE: 'range',
  TOWING_WEIGHT_BRAKES: 'towingWeightBrakes',
  TOWING_WEIGHT_NO_BRAKES: 'towingWeightNoBrakes',
  ROAD_TAX: 'roadTax',
  EMPTY_WEIGHT: 'emptyWeightCars',
};

export const minRanges = [
  RANGE_ATTRIBUTE_KEYS.RANGE,
  RANGE_ATTRIBUTE_KEYS.TOWING_WEIGHT_BRAKES,
  RANGE_ATTRIBUTE_KEYS.TOWING_WEIGHT_NO_BRAKES,
];

export const maxRanges = [
  RANGE_ATTRIBUTE_KEYS.BATTERY_CHARGE_TIME,
  RANGE_ATTRIBUTE_KEYS.BATTERY_FAST_CHARGE_TIME,
  RANGE_ATTRIBUTE_KEYS.ROAD_TAX,
  RANGE_ATTRIBUTE_KEYS.EMPTY_WEIGHT,
];
