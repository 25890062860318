import React, { useEffect, useState } from 'react';

import backgroundImageX1 from '../../../../images/cars-page/cars-l1-image@1x.webp';
import backgroundImageX2 from '../../../../images/cars-page/cars-l1-image@2x.webp';
import backgroundImageEvX1 from '../../../../images/cars-page/cars-ev-image@1x.webp';
import backgroundImageEvX2 from '../../../../images/cars-page/cars-ev-image@2x.webp';
import getIsMobile from '../../../utils/isMobile';
import Classes from './PageBackground.scss';

type TPageBackgroundProps = {
  page: 'cars' | 'ev';
};

const PageBackground = ({ page }: TPageBackgroundProps) => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>();

  useEffect(() => {
    setIsMobile(getIsMobile());
  }, []);

  if (isMobile || typeof isMobile === 'undefined') {
    return null;
  }

  return (
    <div className={Classes.root}>
      {page === 'cars' && (
        <img alt="" src={backgroundImageX1} srcSet={`${backgroundImageX1} 1x, ${backgroundImageX2} 2x`} />
      )}
      {page === 'ev' && (
        <img alt="" src={backgroundImageEvX1} srcSet={`${backgroundImageEvX1} 1x, ${backgroundImageEvX2} 2x`} />
      )}
    </div>
  );
};

export default PageBackground;
