import React, { useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Dropdown } from '@hz-design-system/web-ui';

import { TFacet, TFacetOption } from 'types/TCarsSearchFields';

type TCarsSearchFormDropDownProps = {
  fieldKey: string;
  formField?: TFacet;
  value?: string;
  optionalText?: string;
  errorText?: string;
  disabled?: boolean;
  onChangeHandler: Function;
  fixedField?: boolean;
  withoutLabel?: boolean;
  options?: TFacetOption[];
};

const CarsSearchFormDropDown = ({
  fieldKey,
  formField,
  value,
  optionalText,
  errorText,
  disabled,
  onChangeHandler,
  fixedField,
  withoutLabel,
  options,
}: TCarsSearchFormDropDownProps) => {
  const { t } = useI18nContext();
  const fieldLabel = formField?.label || t(`cars.search.fields.${fieldKey}`);
  const fieldValue = value || '';
  const formFieldOptions = options || formField?.options || [];
  const placeholderText = withoutLabel ? fieldLabel : t('cars.search.options.all');
  const disabledItem = disabled
    ? [
        {
          label: t('cars.search.options.disabled'),
          value: '',
          disabled,
        },
      ]
    : [];
  const defaultOption = formField?.withAllOption
    ? [
        {
          label: withoutLabel
            ? `${fieldLabel}${optionalText ? ` (${optionalText})` : ''}`
            : t([`cars.search.options.all_${fieldKey}`, 'cars.search.options.all']),
          value: '',
        },
      ]
    : [];
  const fieldOptions = defaultOption.concat(disabledItem, formFieldOptions);
  const [focus, setFocus] = useState(false);

  if (!formFieldOptions.length && !formField?.optionGroups && !fixedField) {
    return null;
  }

  const onFocusHandler = () => {
    setFocus(!focus);
  };

  return (
    <Dropdown
      id={fieldKey}
      labelText={withoutLabel ? '' : fieldLabel}
      optionalText={optionalText}
      placeHolderText={disabled ? placeholderText : ''}
      options={formField?.optionGroups ? defaultOption : fieldOptions}
      optGroups={formField?.optionGroups}
      onChange={(e) => onChangeHandler(fieldKey, e.target.value, formField?.type, true)}
      onFocus={onFocusHandler}
      onBlur={onFocusHandler}
      value={fieldValue}
      errorText={errorText}
      className="FormField"
    />
  );
};

export default CarsSearchFormDropDown;
