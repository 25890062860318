import React, { useEffect, useRef, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Card, Tab, TabPanel, Tabs, Divider } from '@hz-design-system/web-ui';
import { currency, number } from '@ecg-marktplaats/aurora-js-formatters';

import './CarsSearch.scss';
import CarsSimpleSearch from './components/CarsSimpleSearch';
import getCarsSearchFields from './repositories/getCarsSearchFields';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import { trackGAEvent } from '../../utils/gaTracking';
import transformForForm from './utils/transformForForm';
import { VEHICLE_TYPES, VEHICLE_TYPE_CATEGORY_IDS } from './constants/vehicleTypes';
import { ATTRIBUTE_VALUES } from '../../../../constants/attributes';
import isPageReloaded from './utils/isPageReloaded';
import { CARS_L1_CATEGORY_ID } from '../../constants/categories';
import SIMPLE_SEARCH_FIELDS from './constants/simpleSeachFields';
import CarsContinueSearch from './components/CarsContinueSearch';

import { TCategoryItemApi } from 'types/TCategories';
import { TCarsSearchFieldsClient, TL2CategoryField } from 'types/TCarsSearchFields';
import { TEnvConfig } from 'types/TServerContext';

type TCarsSearchProps = {
  l1Category: TCategoryItemApi;
  l2CategoryField: TL2CategoryField;
  carsSearchFields: TCarsSearchFieldsClient;
  resultCount: number;
  userPostcode: string;
  environment: TEnvConfig;
  isEvPage?: boolean;
};

const L2_CATEGORY_FIELD = 'l2CategoryId';
const OFFERED_SINCE_FIELD = 'offeredSince';
const LOCAL_STORAGE_KEY = 'lastSearches';
const FIXED_FORM_FIELD_KEYS = [L2_CATEGORY_FIELD, 'model'];
const EV_VEHICLE_TYPE = 'EV';
const EV_FUEL_TYPES = ['11756', '13838', '13839'];

let CarsAdvancedSearch;

const CarsSearchApp = ({
  l1Category,
  l2CategoryField,
  carsSearchFields,
  resultCount,
  userPostcode,
  environment,
  isEvPage,
}: TCarsSearchProps) => {
  const { t } = useI18nContext();
  const {
    experiments: { isBtwSwitchEnabled },
    tenantContext,
    experimentUser,
    priceTypeExperimentVariant,
    houstonClientConfig,
  } = environment;
  const isDefaultPriceTypeForCarsEnabled = priceTypeExperimentVariant === 'B';
  const tenantKey = tenantContext.tenant;
  const withEvTab = tenantKey === 'mp';
  const didMountRef = useRef(false);
  const l2CategoryId = { l2CategoryId: l2CategoryField };
  const tabs = Object.keys(VEHICLE_TYPES);
  if (withEvTab) {
    tabs.splice(1, 0, EV_VEHICLE_TYPE);
  }
  const [vehicleType, setVehicleType] = useState(isEvPage ? EV_VEHICLE_TYPE : VEHICLE_TYPES.CARS);
  const [formFields, setFormFields] = useState({
    [VEHICLE_TYPES.CARS]: { ...carsSearchFields, ...l2CategoryId },
    [VEHICLE_TYPES.VANS]: { ...carsSearchFields, ...l2CategoryId },
    [VEHICLE_TYPES.OLDTIMERS]: { ...carsSearchFields, ...l2CategoryId },
    [VEHICLE_TYPES.TRUCKS]: { ...carsSearchFields, ...l2CategoryId },
    [EV_VEHICLE_TYPE]: { ...carsSearchFields, ...l2CategoryId },
  });
  const initialForm = {
    l2CategoryId: '',
    attributes: {
      model: undefined, // eslint-disable-line no-undefined
    },
    rangeAttributes: {},
    query: '',
    offeredSince: '',
    distance: '',
    postcode: userPostcode || '',
  };
  const buyPriceDefault = isDefaultPriceTypeForCarsEnabled
    ? {}
    : {
        attributes: {
          model: undefined, // eslint-disable-line no-undefined
          priceType: [ATTRIBUTE_VALUES.PRICE_TYPE.BUY],
        },
      };
  const evDefault = {
    attributes: {
      model: undefined, // eslint-disable-line no-undefined
      priceType: [ATTRIBUTE_VALUES.PRICE_TYPE.BUY],
      fuel: [ATTRIBUTE_VALUES.FUEL.ELECTRIC],
    },
  };
  const initialFormState = {
    [VEHICLE_TYPES.CARS]: { ...initialForm, ...buyPriceDefault },
    [VEHICLE_TYPES.VANS]: { ...initialForm, l2CategoryId: VEHICLE_TYPE_CATEGORY_IDS.VANS, ...buyPriceDefault },
    [VEHICLE_TYPES.OLDTIMERS]: { ...initialForm, l2CategoryId: VEHICLE_TYPE_CATEGORY_IDS.OLDTIMERS },
    [VEHICLE_TYPES.TRUCKS]: { ...initialForm, l2CategoryId: VEHICLE_TYPE_CATEGORY_IDS.TRUCKS },
    [EV_VEHICLE_TYPE]: { ...initialForm, ...evDefault },
  };
  const [formState, setFormState] = useState(initialFormState);
  const [brandState, setBrandState] = useState('');
  const [resultCountState, setResultCount] = useState(resultCount);
  const [advancedSearchIsOpen, setAdvancedSearchIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(isEvPage ? 1 : 0);
  const [lastSearch, setLastSearch] = useState<TSearchEntry>();
  const [houston, setHouston] = useState<any>();

  const toggleAdvancedSearch = async (event) => {
    event.preventDefault();
    setFormState({
      ...formState,
      [vehicleType]: transformForForm(formState[vehicleType], advancedSearchIsOpen, formFields[vehicleType]),
    });
    if (!advancedSearchIsOpen) {
      trackGAEvent('showSearchAdvanced');
    }
    try {
      CarsAdvancedSearch = (
        await import(/* webpackChunkName: "CarsAdvancedSearch" */ './components/CarsAdvancedSearch')
      ).default;
      setAdvancedSearchIsOpen(!advancedSearchIsOpen);
    } catch (e) {}
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let link, filtersCount;

    try {
      const createLinks = (await import(/* webpackChunkName: "createLinks" */ '../../utils/linkHelpers')).default;
      ({ link, filtersCount } = createLinks({
        l1Category,
        formFields: formFields[vehicleType],
        formState: formState[vehicleType],
      }));
    } catch (e) {}
    const formType = advancedSearchIsOpen ? 'Advanced' : 'Simple';
    trackGAEvent(`formSubmitted${formType}`, filtersCount.toString());
    trackGAEvent(`searchSubmitted`, vehicleType);

    const isFuelTypeElectric = formState[vehicleType].attributes?.['fuel']
      ? formState[vehicleType].attributes?.['fuel']?.some((value) => EV_FUEL_TYPES.includes(value))
      : false;
    if (isFuelTypeElectric) {
      trackGAEvent(`electricFuelSubmitted`, formState[vehicleType].attributes?.['fuel'].join());
    }

    if (formState[vehicleType].attributes?.['priceType']) {
      trackGAEvent('priceTypeSubmit', formState[vehicleType].attributes?.['priceType'].join());
    }

    // Using history API as it's part of the navigation history and doesn't require to handle invalidation of the state
    window.history.replaceState(formState, '', window.location.href);
    window.location.assign(link);
  };

  const onReset = () => {
    setFormState({ ...formState, [vehicleType]: initialFormState[vehicleType] });
    setBrandState('');
  };

  const handleChange = (name, value, type, singleSelect) => {
    const fieldName = name.split('.')[0]; // Needed for range attributes
    const formField = formFields[vehicleType][fieldName];
    const updatedFormState = { ...formState[vehicleType] };

    if (type === 'AttributeGroupFacet' && fieldName !== OFFERED_SINCE_FIELD) {
      let updatedAttribute = formState[vehicleType].attributes[name];
      const isAttributeSet = updatedAttribute;

      if (!formField) {
        return;
      }

      if (singleSelect || !isAttributeSet) {
        // replace values
        updatedAttribute = [value];
      } else {
        // toggle only value from args
        const index = updatedAttribute.indexOf(value);
        if (index >= 0) {
          updatedAttribute.splice(index, 1);
        } else {
          updatedAttribute.push(value);
        }
      }

      updatedFormState.attributes = {
        ...formState[vehicleType]['attributes'],
        [name]: updatedAttribute,
      };
    } else if (type === 'AttributeRangeFacet') {
      const rangeAttributes = { ...formState[vehicleType]['rangeAttributes'] };
      const subField = name.split('.')[1];
      const existingValue = rangeAttributes[fieldName];

      if (existingValue) {
        const updatedAttribute = existingValue;
        updatedAttribute[subField] = value;
        rangeAttributes[fieldName] = updatedAttribute;
      } else {
        const updatedAttribute = {
          attributeKey: fieldName,
        };
        updatedAttribute[subField] = value;
        rangeAttributes[fieldName] = updatedAttribute;
      }

      updatedFormState.rangeAttributes = rangeAttributes;
    } else {
      updatedFormState[name] = value;
    }

    if (name === L2_CATEGORY_FIELD) {
      const formFieldsWithoutModel = { ...formFields[vehicleType] };
      delete formFieldsWithoutModel.model;

      setBrandState(value);
      setFormFields({ ...formFields, [vehicleType]: formFieldsWithoutModel });

      if (updatedFormState.attributes.model) {
        delete updatedFormState.attributes.model;
      }
    }

    setFormState({ ...formState, [vehicleType]: updatedFormState });
  };

  useEffect(() => {
    const fetchCarsSearchFields = async (): Promise<void> => {
      const formStateArg = formState[vehicleType];
      const searchFields = await getCarsSearchFields(brandState, formStateArg);

      if (searchFields && searchFields.facets && Object.keys(searchFields.facets).length) {
        const facets = { ...searchFields.facets };
        const updatedFormFields = { ...formFields[vehicleType], ...facets };

        Object.keys(formFields[vehicleType]).forEach((formFieldKey) => {
          if (!facets[formFieldKey] && !FIXED_FORM_FIELD_KEYS.includes(formFieldKey)) {
            delete updatedFormFields[formFieldKey];
          }
        });

        setFormFields({
          ...formFields,
          [vehicleType]: updatedFormFields,
        });
      }

      if (searchFields.totalResultCount) {
        setResultCount(searchFields.totalResultCount);
      }
    };

    if (didMountRef.current) {
      fetchCarsSearchFields().then(() => {
        // do nothing, lint fix
      });
    } else {
      didMountRef.current = true;
    }
  }, [formState, vehicleType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let updatedVehicleType;
    switch (activeTab) {
      case 1: // eslint-disable-line no-magic-numbers
        updatedVehicleType = withEvTab ? EV_VEHICLE_TYPE : VEHICLE_TYPES.VANS;
        break;
      case 2: // eslint-disable-line no-magic-numbers
        updatedVehicleType = withEvTab ? VEHICLE_TYPES.VANS : VEHICLE_TYPES.OLDTIMERS;
        break;
      case 3: // eslint-disable-line no-magic-numbers
        updatedVehicleType = withEvTab ? VEHICLE_TYPES.OLDTIMERS : VEHICLE_TYPES.TRUCKS;
        break;
      case 4: // eslint-disable-line no-magic-numbers
        updatedVehicleType = VEHICLE_TYPES.TRUCKS;
        break;
      default:
        updatedVehicleType = VEHICLE_TYPES.CARS;
        break;
    }

    if (updatedVehicleType === EV_VEHICLE_TYPE) {
      setBrandState('');
    }
    trackGAEvent('l1TabSelected', updatedVehicleType);
    setVehicleType(updatedVehicleType);
  }, [activeTab, withEvTab]);

  useEffect(() => {
    const priceTypeState = formState[VEHICLE_TYPES.CARS]['attributes']['priceType'];
    const priceCentsState = formState[VEHICLE_TYPES.CARS]['rangeAttributes']['PriceCents'];
    const formFieldsWithLeasePrices = { ...formFields };
    const priceCentsField = formFieldsWithLeasePrices[VEHICLE_TYPES.CARS].PriceCents;

    if (priceCentsField && priceTypeState) {
      if (priceTypeState.length === 1 && priceTypeState[0] === ATTRIBUTE_VALUES.PRICE_TYPE.LEASE) {
        priceCentsField.label = priceCentsField.leaseLabel;
        if (priceCentsField.leaseOptions) {
          priceCentsField.options = priceCentsField.leaseOptions;
        }
        setFormFields(formFieldsWithLeasePrices);
      } else {
        priceCentsField.label = priceCentsField.buyLabel;
        if (priceCentsField.buyOptions) {
          priceCentsField.options = priceCentsField.buyOptions;
        }
      }

      if (
        priceCentsState &&
        !advancedSearchIsOpen &&
        !priceCentsField.options.find((option) => option.value === priceCentsState?.to)
      ) {
        delete formState[VEHICLE_TYPES.CARS]['rangeAttributes']['PriceCents'];
      }
    }
  }, [formFields.cars.priceType, formState, advancedSearchIsOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window?.history?.state && !isPageReloaded()) {
      setFormState(window?.history?.state);
    }
  }, []);

  useEffect(() => {
    if (isEvPage) {
      (async () => {
        try {
          const houstonModule = await import(/* webpackChunkName: "houston" */ '@ecg-marktplaats/houston-integration');
          await houstonModule.createOptimizelyClient({ houstonConfig: houstonClientConfig });
          setHouston(houstonModule);
        } catch (err) {}
      })();
    }
  }, [isEvPage, houstonClientConfig]);

  useEffect(() => {
    if (!houston) {
      return;
    }

    houston.trackExperiment({
      eventKey: 'ev_page_view',
      experimentUser,
      tenantKey,
    });
  }, [experimentUser, houston, tenantKey]);

  useEffect(() => {
    try {
      const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (localStorageValue) {
        const parsedValue = JSON.parse(localStorageValue);
        const carsLastSearch = parsedValue.filter((entry) => entry.l1CategoryId === CARS_L1_CATEGORY_ID);

        if (carsLastSearch.length) {
          const { search, attributeLabels } = carsLastSearch[0];
          const priceRangeFilter = search.attributeRanges.find(({ attributeKey }) => attributeKey === 'PriceCents')?.[
            'to'
          ];
          const mileageRangeFilter = search.attributeRanges.find(({ attributeKey }) => attributeKey === 'mileage')?.[
            'to'
          ];
          const constructionYearRangeFilter = search.attributeRanges.find(
            ({ attributeKey }) => attributeKey === 'constructionYear',
          )?.['from'];
          const intersectingFuelTypes = search.attributesById.filter((element) =>
            EV_FUEL_TYPES.includes(element.toString()),
          );
          Object.keys(attributeLabels).forEach((attributeLabelKey) => {
            const attributeLabel = attributeLabels[attributeLabelKey];
            if (['PriceCents', 'mileage', 'constructionYear'].includes(attributeLabel.facetId)) {
              delete attributeLabels[attributeLabelKey];
            }
          });

          if (isEvPage && !intersectingFuelTypes.length) {
            return;
          }

          const buildLrpUrl = async (): Promise<void> => {
            try {
              const { lrpUrl } = await import(
                /* webpackChunkName: "lrpUrl" */ '@ecg-marktplaats/aurora-node-url-builder'
              );
              setLastSearch({
                l2CategoryName: search.categories.l2Category?.fullName,
                priceRangeFilter: priceRangeFilter ? currency(priceRangeFilter, true, true) : priceRangeFilter,
                constructionYearRangeFilter,
                mileageRangeFilter: mileageRangeFilter ? number(mileageRangeFilter) : mileageRangeFilter,
                filterCount: search.attributesById.length,
                attributeLabels,
                link: lrpUrl({
                  categories: search.categories,
                  attributes: search.attributes,
                  rangeAttributes: search.attributeRanges,
                  distance: search.distance?.distanceMeters,
                  postcode: search.distance?.postcode,
                  view: search.viewOptions?.kind,
                  query: search.searchQuery,
                  attributesByKey: search.attributesByKey,
                }),
              });
            } catch (e) {}
          };

          buildLrpUrl();
        }
      }
    } catch (e) {}
  }, [isEvPage]);

  return (
    <EnvironmentProvider value={environment}>
      <Card className="tabs-Card">
        <div className="vehicle-Tabs">
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} isFluid>
            {tabs.map((vehicle) => {
              if (vehicle === EV_VEHICLE_TYPE || isEvPage) {
                return (
                  <a
                    key={vehicle}
                    className={`tabLink hz-TabNext ${vehicle === EV_VEHICLE_TYPE && isEvPage ? 'hz-TabNext--active' : ''}`}
                    href={vehicle === EV_VEHICLE_TYPE ? '/cp/elektrische-auto-kopen/' : '/cp/91/auto-kopen/'}
                    onClick={() => {}}
                  >
                    <span className="hz-Text hz-Text--bodyLarge u-colorTabBarUnselectedDefault">
                      {t(`cars.browse.${VEHICLE_TYPES[vehicle] || 'evs'}`)}
                    </span>
                  </a>
                );
              } else {
                return <Tab key={vehicle} label={t(`cars.browse.${VEHICLE_TYPES[vehicle]}`)} />;
              }
            })}
          </Tabs>
        </div>
        <Divider />
      </Card>
      <Card className="search-Card">
        <section>
          <TabPanel activeTab={activeTab} tab={0}>
            <CarsSimpleSearch
              formFields={formFields[vehicleType]}
              onChange={handleChange}
              onSubmit={onSubmit}
              toggleAdvancedSearch={toggleAdvancedSearch}
              resultCount={resultCountState}
              formState={formState[vehicleType]}
              lastSearch={lastSearch}
              simpleSearchKeys={SIMPLE_SEARCH_FIELDS[VEHICLE_TYPES.CARS]}
            />
          </TabPanel>
          {isEvPage && (
            <TabPanel activeTab={activeTab} tab={1}>
              <CarsSimpleSearch
                formFields={formFields[vehicleType]}
                onChange={handleChange}
                onSubmit={onSubmit}
                toggleAdvancedSearch={toggleAdvancedSearch}
                resultCount={resultCountState}
                formState={formState[vehicleType]}
                lastSearch={lastSearch}
                simpleSearchKeys={SIMPLE_SEARCH_FIELDS[EV_VEHICLE_TYPE]}
              />
            </TabPanel>
          )}
          {/* eslint-disable-next-line no-magic-numbers */}
          <TabPanel activeTab={activeTab} tab={withEvTab ? 2 : 1}>
            <CarsSimpleSearch
              formFields={formFields[vehicleType]}
              onChange={handleChange}
              onSubmit={onSubmit}
              toggleAdvancedSearch={toggleAdvancedSearch}
              resultCount={resultCountState}
              formState={formState[vehicleType]}
              simpleSearchKeys={SIMPLE_SEARCH_FIELDS[VEHICLE_TYPES.VANS]}
            />
          </TabPanel>
          {/* eslint-disable-next-line no-magic-numbers */}
          <TabPanel activeTab={activeTab} tab={withEvTab ? 3 : 2}>
            <CarsSimpleSearch
              formFields={formFields[vehicleType]}
              onChange={handleChange}
              onSubmit={onSubmit}
              toggleAdvancedSearch={toggleAdvancedSearch}
              resultCount={resultCountState}
              formState={formState[vehicleType]}
              simpleSearchKeys={SIMPLE_SEARCH_FIELDS[VEHICLE_TYPES.OLDTIMERS]}
            />
          </TabPanel>
          {/* eslint-disable-next-line no-magic-numbers */}
          <TabPanel activeTab={activeTab} tab={withEvTab ? 4 : 3}>
            <CarsSimpleSearch
              formFields={formFields[vehicleType]}
              onChange={handleChange}
              onSubmit={onSubmit}
              toggleAdvancedSearch={toggleAdvancedSearch}
              resultCount={resultCountState}
              formState={formState[vehicleType]}
              simpleSearchKeys={SIMPLE_SEARCH_FIELDS[VEHICLE_TYPES.TRUCKS]}
            />
          </TabPanel>
        </section>
      </Card>

      {advancedSearchIsOpen && (
        <CarsAdvancedSearch
          formFields={formFields[vehicleType]}
          onChange={handleChange}
          onSubmit={onSubmit}
          toggleAdvancedSearch={toggleAdvancedSearch}
          resultCount={resultCountState}
          formState={formState[vehicleType]}
          onReset={onReset}
          isBtwSwitchEnabled={isBtwSwitchEnabled}
        />
      )}

      {lastSearch && <CarsContinueSearch searchEntry={lastSearch} asCard={true} />}
    </EnvironmentProvider>
  );
};

export default CarsSearchApp;
