import React from 'react';

import { EnvironmentProvider } from '../../contexts/EnvironmentContext';
import SellBlock from './components/SellBlock';

import { TEnvConfig } from 'types/TServerContext';

type TSellBlockAppProps = {
  withLicensePlateField: boolean;
  envConfig: TEnvConfig;
};

const SellBlockApp = ({ envConfig, withLicensePlateField }: TSellBlockAppProps) => (
  <EnvironmentProvider value={envConfig}>
    <SellBlock withLicensePlateField={withLicensePlateField} />
  </EnvironmentProvider>
);

export default SellBlockApp;
