import React from 'react';

import Carousel from './Carousel';
import CarouselItem from './CarouselItem';
import { trackGAEvent } from '../../../utils/gaTracking';

import { TSearchItemCarousel } from 'types/TCarousels';

type TCarouselsProps = {
  carousel: TSearchItemCarousel;
};

const SearchCarousel = ({ carousel }: TCarouselsProps) => {
  const onCLickHandler = (carouselType, index) => {
    trackGAEvent('Clicked', carouselType, index);
  };

  return (
    <Carousel key={carousel.type} id={carousel.type}>
      {carousel.items.map((item, index) => (
        <CarouselItem
          withSubtitle
          key={`${item.key}-${index}`}
          item={item}
          withBackgroundColor
          onClick={() => onCLickHandler(carousel.type, index)}
          eagerLoadImage={index <= 1}
        />
      ))}
    </Carousel>
  );
};

export default SearchCarousel;
