import React, { useEffect, useRef, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { SecondaryIconButton, Title5 } from '@hz-design-system/web-ui';

import { trackGAEvent } from '../../../utils/gaTracking';

import Classes from './Carousels.scss';

type TCarouselProps = {
  id: string;
  children: React.ReactNode;
};

const Carousel = ({ id, children }: TCarouselProps) => {
  const { t } = useI18nContext();
  const carouselRef = useRef<HTMLInputElement>(null);
  const [navigationEnabled, setNavigationEnabled] = useState(false);
  const [carouselRefWidth, setCarouselRefWidth] = useState(0);
  const [carouselOffset, setCarouselOffset] = useState(0);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [scrolledToBegin, setScrolledToBegin] = useState(false);
  const whyDoINeedThis = 3;

  const isScrolledToEnd = (element) => {
    return element ? element.offsetWidth + element.scrollLeft >= element.scrollWidth - whyDoINeedThis : false;
  };

  const isScrolledToBegin = (element) => {
    return element ? element.scrollLeft === 0 : false;
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      setCarouselOffset(carouselRef.current.scrollLeft);
      setCarouselOffset(carouselRef.current.scrollLeft + carouselRefWidth);
    }
  };

  const handlePreviousClick = () => {
    if (carouselRef.current) {
      setCarouselOffset(carouselRef.current.scrollLeft);
      setCarouselOffset(carouselRef.current.scrollLeft - carouselRefWidth);
    }
  };

  useEffect(() => {
    trackGAEvent('Displayed', `${id}_carousel`);
  }, [id]);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    const handleScroll = (event) => {
      const element = event.currentTarget;
      setScrolledToEnd(isScrolledToEnd(element));
      setScrolledToBegin(isScrolledToBegin(element));
      setCarouselOffset(element.scrollLeft);
    };

    if (carouselElement) {
      const scrolledToEndAtInit = isScrolledToEnd(carouselElement);
      setScrolledToEnd(scrolledToEndAtInit);
      setScrolledToBegin(isScrolledToBegin(carouselElement));
      setNavigationEnabled(!scrolledToEndAtInit);
      setCarouselRefWidth(carouselElement.offsetWidth);
      carouselElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const carouselElement = carouselRef.current;

      if (carouselElement) {
        const scrolledToEndAtInit = isScrolledToEnd(carouselElement);
        setScrolledToEnd(scrolledToEndAtInit);
        setScrolledToBegin(isScrolledToBegin(carouselElement));
        setNavigationEnabled(!scrolledToEndAtInit);
        setCarouselRefWidth(carouselElement.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const carouselElement = carouselRef.current;

    if (carouselElement && carouselElement.scrollLeft !== carouselOffset) {
      carouselElement.scrollTo({
        left: carouselOffset,
        behavior: 'smooth',
      });
    }
  }, [carouselOffset]);

  return (
    <div className={Classes.container}>
      <Title5 className={Classes.title}>{t(`cars.carousel.title.${id}`)}</Title5>
      <div className={Classes.grid} ref={carouselRef}>
        {children}
      </div>
      {navigationEnabled && !scrolledToBegin && (
        <SecondaryIconButton
          className={Classes.previousButton}
          ariaLabel={t(`cars.carousels.nav.previous`)}
          name="back"
          onClick={handlePreviousClick}
        />
      )}
      {navigationEnabled && !scrolledToEnd && (
        <SecondaryIconButton
          className={Classes.nextButton}
          ariaLabel={t(`cars.carousels.nav.next`)}
          name="arrow_tailed_right"
          onClick={handleNextClick}
        />
      )}
    </div>
  );
};

export default Carousel;
