import React, { useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Card, Link, SecondaryButton, LicensePlateTextField } from '@hz-design-system/web-ui';

import { trackGAEvent } from '../../../utils/gaTracking';

import Classes from './SellBlock.scss';

type TSellBlockProps = {
  withLicensePlateField: boolean;
};

const SellBlock = ({ withLicensePlateField }: TSellBlockProps) => {
  const { t } = useI18nContext();
  const [licensePlate, setLicensePlate] = useState('');

  return (
    <>
      <Card padding="s" className={Classes.root}>
        <h2 className="hz-Text hz-Text--bodyLargeStrong sell-Title">{t('cars.sell.title2')}</h2>
        <p className={`hz-Text hz-Text--bodyRegular ${Classes.Text}`}>{t('cars.sell.text')}</p>
        <form method="get" action="/plaats/91" className={`hz-Form ${Classes.Form}`}>
          {withLicensePlateField && (
            <LicensePlateTextField
              id="licensePlate"
              value={licensePlate}
              locale="nlnl"
              countryCode="NL"
              onChange={(e) => setLicensePlate(e.target.value)}
            />
          )}
          <div className="sell-Buttons">
            {withLicensePlateField && (
              <Link
                href={`/m/auto/auto-verkopen/?licensePlate=${licensePlate}`}
                onClick={() => {
                  trackGAEvent('checkSellPriceClicked');
                }}
              >
                {t('cars.sell.check_price')}
              </Link>
            )}
            <SecondaryButton
              type="submit"
              className="place-ad-button"
              data-sem="placeAdAction"
              onClick={() => {
                trackGAEvent('placeAdButtonClicked');
              }}
            >
              {t('cars.sell.place_ad_button')}
            </SecondaryButton>
          </div>
        </form>
      </Card>
    </>
  );
};

export default SellBlock;
