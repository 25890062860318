export enum VEHICLE_TYPES {
  CARS = 'cars',
  VANS = 'vans',
  OLDTIMERS = 'oldtimers',
  TRUCKS = 'trucks',
}

export enum VEHICLE_TYPE_CATEGORY_IDS {
  CARS = '91',
  VANS = '95',
  TRUCKS = '159',
  OLDTIMERS = '137',
}
