const PRICE_CENTS_FIELD = 'PriceCents';
const CONSTRUCTION_YEAR_FIELD = 'constructionYear';
const SIMPLE_FORM_FIELD_KEYS = [
  'l2CategoryId',
  'brand',
  'model',
  'PriceCents',
  'body',
  'fuel',
  'constructionYear',
  'priceType',
];

const filterFields = (fields, allowedFields, keepAllValues = false) =>
  Object.keys(fields)
    .filter((key) => allowedFields.includes(key))
    .reduce((obj, key) => {
      if (keepAllValues || fields[key]?.length <= 1) {
        obj[key] = fields[key];
      }
      return obj;
    }, {});

export default function transformForForm(formState, toSimpleForm, formFields) {
  const newFormState = { ...formState };
  if (toSimpleForm) {
    newFormState.attributes = filterFields(newFormState.attributes, SIMPLE_FORM_FIELD_KEYS);
    newFormState.rangeAttributes = filterFields(newFormState.rangeAttributes, SIMPLE_FORM_FIELD_KEYS, true);

    if (newFormState?.rangeAttributes?.PriceCents) {
      newFormState.rangeAttributes[PRICE_CENTS_FIELD]['from'] = '';
      const priceCentsToValue = newFormState.rangeAttributes[PRICE_CENTS_FIELD]['to'];
      const priceCentsFieldOptions = formFields[PRICE_CENTS_FIELD].options;
      const hasMatchingOption = priceCentsFieldOptions.findIndex((option) => option.value === priceCentsToValue) >= 0;
      if (!hasMatchingOption) {
        newFormState.rangeAttributes[PRICE_CENTS_FIELD]['to'] = '';
      }
    }

    if (newFormState?.rangeAttributes?.constructionYear) {
      newFormState.rangeAttributes[CONSTRUCTION_YEAR_FIELD]['to'] = '';
    }
  }
  return newFormState;
}
