import React, { useContext, useState } from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Tabs, Tab, TabPanel, Link, PageContent } from '@hz-design-system/web-ui';
import carsTopCategoryLinks from '../../../../../../content/carsTopCategoryLinks/index.json';
import Classes from './CarsCategoryLinks.scss';
import { TCategoryItem } from '@hz-design-system/web-ui/src/types/TCategoryItem';
import EnvironmentContext from '../../../contexts/EnvironmentContext';

type TCarsCategoryLinksProps = {
  carsCategoryLinks: TCategoryItem[];
};

const CategoryLinks = ({ carsCategoryLinks }: TCarsCategoryLinksProps) => {
  const { t } = useI18nContext();
  const {
    links: { carsSearchBaseUrl },
  } = useContext(EnvironmentContext);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className={Classes.TabsContainer}>
        <PageContent as="section">
          <Tabs className={Classes.Tabs} activeTab={activeTab} setActiveTab={setActiveTab}>
            <Tab label={t('cars.links.tabs.top_models')} />
            <Tab label={t('cars.links.tabs.all_brands')} />
          </Tabs>
        </PageContent>
      </div>
      <div className={`Page-section Page-section--full-width ${Classes.TabPanels}`}>
        <PageContent className="Page--wrapper">
          <section className="Page-section linksSection">
            <TabPanel activeTab={activeTab} tab={0}>
              <div className={Classes.list} data-sem="carTopModelSearchLinkBlock">
                <div className={Classes.subListSection}>
                  {carsTopCategoryLinks.map(({ category, models }) => (
                    <ul className={Classes.subList} key={category.name}>
                      <li>
                        <h4 className={Classes.l2CategoryName}>
                          <Link
                            href={`${carsSearchBaseUrl}${category.url}`}
                            className="car-brand"
                            data-sem="carBrandSearchDisplayAction"
                          >
                            {category.name}
                          </Link>
                        </h4>
                      </li>
                      {models.map(({ url, name }) => (
                        <li key={name}>
                          <Link
                            href={`${carsSearchBaseUrl}${url}`}
                            className="car-model"
                            data-sem="carModelSearchDisplayAction"
                          >
                            {name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ))}
                </div>
              </div>
            </TabPanel>
            <TabPanel activeTab={activeTab} tab={1}>
              <ul className={Classes.AllBrandsList}>
                {carsCategoryLinks.map(({ id, name, url }) => (
                  <li key={id}>
                    <Link className="hz-Link--navigation" href={url}>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </TabPanel>
          </section>
        </PageContent>
      </div>
    </>
  );
};

export default CategoryLinks;
