// Runtime webpack config
import '../../other/WebpackConfig';

import '../../../scss/l1CarsPage.mp.nlnl.scss';

// i18n
import i18n from '../../i18n/nl-NL';

import l1CarsPage from './l1CarsPage';

l1CarsPage(i18n);
