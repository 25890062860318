import fetchAPI from '../../../utils/fetchApi';
import formatQueryString from '../../../utils/formatQueryString';

export default async function getCarsSearchFields(brandValue, formState) {
  let attributes: string[] = [];
  const rangeAttributes: object = {};
  const {
    l2CategoryId,
    attributes: formStateAttributes = {},
    rangeAttributes: formStateRangeAttributes = {},
    offeredSince,
    query,
    distance,
    postcode,
  } = formState;

  const resolvedL2CategoryId = l2CategoryId || brandValue;

  Object.keys(formStateAttributes).forEach((key) => {
    const value = formStateAttributes[key];
    const allValue = value?.length === 1 && value[0] === '';

    if (value && !allValue) {
      attributes = attributes.concat(value);
    }
  });

  Object.keys(formStateRangeAttributes).forEach((attributeKey) => {
    const attribute = formStateRangeAttributes[attributeKey];
    if (attribute.from) {
      rangeAttributes[`${attributeKey}[from]`] = attribute.from;
    }
    if (attribute.to) {
      rangeAttributes[`${attributeKey}[to]`] = attribute.to;
    }
  });

  const flattenedParams = {
    ...(resolvedL2CategoryId && { l2CategoryId: resolvedL2CategoryId }),
    ...(attributes.length && { attributes }),
    ...(rangeAttributes && rangeAttributes),
    ...(offeredSince && { offeredSince }),
    ...(query && { query }),
    ...(postcode && distance && { ['distance[postcode]']: postcode, ['distance[distanceMeters]']: distance }),
  };

  try {
    return await fetchAPI({ url: `/cp/api/cars-search-fields?${formatQueryString(flattenedParams)}` });
  } catch (err) {
    return {
      facets: {},
    };
  }
}
